<template>
    <div class="flex flex-col-reverse lg:flex-row relative py-16">
        <div class="lg:w-3/4">
            <ul>
                <li>
                    If you have a question or need technical support, you can
                    call:
                    <a href="tel:4696763392"><strong>(469) 676-3392</strong></a>
                </li>
                <li>
                    From within the Midsummer virtual meeting room, you can
                    click on the Live Chat icon in the bottom right corner and
                    start a live chat session
                </li>
                <li>
                    Live support via phone and chat are available these hours:
                    <ul class="mb-0">
                        <li>
                            <span class="w-48 font-bold inline-block"
                                >July 1st, 2nd &amp; 6th:</span
                            >
                            <span class="ml-1">10:00am - 4:00pm ET</span>
                        </li>
                        <li>
                            <span class="w-48 font-bold inline-block"
                                >July 7th - 10th:</span
                            >
                            <span class="ml-1">10:00am - 6:00pm ET</span>
                        </li>
                        <li>
                            <span class="w-48 font-bold inline-block"
                                >July 13th - 16th:</span
                            >
                            <span class="ml-1">10:00am - 6:00pm ET</span>
                        </li>
                    </ul>
                </li>
                <li>
                    The technical support line and live chat will be staffed by
                    FMI and event staff to assist with logins, meeting access,
                    and technical challenges.
                </li>
                <li>
                    Email us at
                    <a href="mailto:">midsummer@fmi.org</a> at any time
                </li>
                <li>
                    A training tutorial for the platform is available
                    <a
                        href="https://www.youtube.com/watch?v=g_zQmXr4Ais&feature=youtu.be"
                        target="_blank"
                        rel="noopener noreferrer"
                        >here</a
                    >.
                </li>
            </ul>
            <h2 id="faq">Frequently Asked Questions</h2>
            <div>
                <h3 id="login">Logging in to Midsummer</h3>
                <p>
                    Since you are accessing these FAQs from within the Midsummer
                    virtual meeting, we assume you were able to log in
                    successfully.
                </p>
                <ul>
                    <li>
                        <p>
                            <strong>Can someone else log in for me?</strong
                            ><br />
                            No, your login information is specific to you and
                            should be kept private. For security purposes, you
                            can only be logged in from 1 location at a time.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >What equipment and technical specifications are
                                needed to access Midsummer and participate in
                                the Strategic Executive Exchange private
                                meetings?</strong
                            ><br />
                            If you can access the web, you can participate in
                            Midsummer! However, to be successful we strongly
                            encourage using a PC or Mac with a keyboard, camera,
                            microphone, and strong internet connection if you
                            will be participating in the Strategic Executive
                            Exchange meetings.
                        </p>
                        <p>
                            In general, here are the specific requirements:
                        </p>
                        <ul>
                            <li>
                                PC, Mac, tablet, or smartphone (PC or Mac is
                                highly recommended)
                            </li>
                            <li>
                                Internet connection (wired is better than wi-fi)
                            </li>
                            <li>
                                Modern web browser. Midsummer will support the
                                latest versions of Chrome, Safari, Firefox, and
                                Edge. IE11 is not fully supported and you should
                                use a different browser.
                            </li>
                            <li>
                                <strong
                                    >Please upgrade to the latest version of
                                    your chosen web browser. Although our system
                                    supports several versions of Chrome and
                                    Firefox, we do not support older versions of
                                    Edge or Internet Explorer.</strong
                                >
                            </li>
                            <li>
                                <strong
                                    >If using Microsoft Edge, please make sure
                                    you have the latest version. You can
                                    download it
                                    <a
                                        href="https://www.microsoft.com/en-us/edge?form=MY01BL&OCID=MY01BL"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >here</a
                                    >.</strong
                                >
                            </li>
                            <li>
                                If you plan to participate in Midsummer from a
                                tablet or smartphone, there are certain features
                                that will not be enabled for you, including
                                screen sharing and chat in the Strategic
                                Executive Exchange private meetings.
                            </li>
                            <li>
                                An external or integrated web cam if you wish to
                                share your video during the Strategic Executive
                                Exchange meetings in the v-Collaboration Suites
                            </li>
                            <li>
                                An external or integrated microphone if you wish
                                to have audio during the Strategic Executive
                                Exchange meetings in the v-Collaboration Suites
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <strong
                                >Can I access the platform before the event
                                begins to confirm my equipment will
                                work?</strong
                            ><br />
                            Yes, attendees will be given access to the virtual
                            event platform beginning July 1st. On July 2nd and
                            July 6th, a test version of the Strategic Executive
                            Exchange private meetings will be available from
                            10:00am – 4:00pm Eastern Time. Visit the “MY
                            SCHEDULE” tab to access the meeting on your
                            schedule. In the test meeting, you can test your
                            camera and microphone and explore the features and
                            controls of the v-Collaboration Suite. Please note
                            that the room is open to ALL attendees during this
                            time. It is not a private room.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>How do I sign up for notifications?</strong
                            ><br />
                            You can sign up for notifications by clicking on the
                            “bell” icon on the bottom left hand corner of the
                            screen. Notifications can be sent to desktop
                            browsers or mobile devices.
                        </p>
                    </li>
                </ul>
                <p>
                    <a href="#top"><strong>Back to Top</strong></a>
                </p>
            </div>
            <div>
                <h3 id="scheduled-meetings">Scheduled Meetings</h3>
                <ul>
                    <li>
                        <p>
                            <strong
                                >In what time zone are the meetings
                                scheduled?</strong
                            ><br />
                            All times displayed on the Midsummer site are in
                            Eastern Time (ET).
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >Where do I see my confirmed meetings?</strong
                            ><br />
                            You can see and access your confirmed meetings in
                            the “MY SCHEDULE” tab at the top of the screen.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >Why does my schedule include all my company’s
                                SEEs meetings – do I need to attend all of
                                them?</strong
                            ><br />
                            Your schedule will show all meetings that have been
                            scheduled by your company; you only need to attend
                            the meetings you were designated to attend.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >Can suppliers do a rehearsal prior to the
                                meeting if presenting?</strong
                            ><br />
                            Suppliers can access the meeting room 15 minutes
                            prior to the scheduled time to rehearse.
                        </p>
                        <p>
                            We have also set up a test room that is accessible
                            on Thursday, July 2nd and Monday, July 6th from
                            10:00am – 4:00pm ET. It is open to ALL suppliers and
                            retailers and members of the FMI staff and Matrix
                            Group will be on hand to answer any questions.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >How do I access my SEEs private
                                meetings?</strong
                            ><br />
                            Click on the “MY SCHEDULE” tab in the top menu bar.
                            Your meetings will be displayed within the calendar.
                            The calendar will open to the current upcoming date.
                        </p>
                    </li>
                    <li>
                        <strong
                            >What features are available in the SEEs private
                            meetings?</strong
                        >
                        <ul>
                            <li>Video and presentation sharing</li>
                            <li>Webcam and audio</li>
                            <li>Share your screen</li>
                            <li>Mute/unmute yourself</li>
                            <li>Chat with all attendees</li>
                            <li>Grid or speaker views</li>
                            <li>
                                Adjust your settings to switch your camera or
                                audio input/output
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <strong
                                >How soon before the scheduled start time can I
                                access the private meeting room?</strong
                            ><br />
                            Suppliers can access the private meeting room 15
                            minutes prior to the scheduled meeting start time.
                            Retailers can access the private meeting rooms 5
                            minutes prior to the start time.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >Can we continue the meeting past the scheduled
                                stop time?</strong
                            ><br />
                            There is a 10-minute grace period after the
                            45-minute meeting. After 10 minutes, the meeting
                            will automatically end, and participants will not be
                            able to re-enter the room.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >What if I become disconnected from the
                                meeting?</strong
                            ><br />
                            Return to the “MY SCHEDULE” page and click on the
                            meeting link again. If you are having problems
                            joining the meeting, please click on the “HELP” tab
                            in the top menu bar or on the “Live Chat” icon in
                            the bottom right hand corner of your screen.
                        </p>
                    </li>
                    <li>
                        <strong>How do we prepare for the meeting?</strong>
                        <ul>
                            <li>
                                Log in to the site to make sure your meetings
                                are correct. If anything is missing or
                                incorrect, please contact Marc Thomas at
                                mthomas@fmi.org
                            </li>
                            <li>
                                Read the “Best Practices For Meeting
                                Participation” below
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong
                            >What if the private meeting does not recognize my
                            preferred speaker and camera?</strong
                        >
                        <ul>
                            <li>
                                If you have multiple speakers, microphones, and
                                cameras, you will need to tell the private
                                meeting rooms about your preferences.
                            </li>
                            <li>
                                Click on the Gear icon in the lower right corner
                                of the private meeting rooms.
                            </li>
                            <li>
                                Select Devices, then select your preferred
                                speaker, microphone, and camera.
                            </li>
                            <li>
                                Your preferences will be saved on that browser.
                                If you log in from another browser, you may need
                                to select your Device options again.
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>
                    <a href="#top"><strong>Back to Top</strong></a>
                </p>
            </div>
            <div>
                <h3 id="insights">Insights and Learnings</h3>
                <ul>
                    <li>
                        <p class="mb-1">
                            <strong
                                >How do I access the live-streamed Insights and
                                Learnings Sessions?</strong
                            ><br />
                            There are two ways to access these:
                        </p>
                        <ul>
                            <li>
                                Click on the “INSIGHTS” tab on the top menu bar
                                to access the Insights &amp; Learnings sessions.
                                Upcoming live airings will be displayed in the
                                “Upcoming Sessions” tab.
                            </li>
                            <li>
                                Click on “MY SCHEDULE.” All Insights sessions
                                will automatically appear in your schedule.
                            </li>
                        </ul>
                        <p>
                            If you miss the live airing, you can click on the
                            “On Demand” tab to watch the recording later.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >What if I miss the live-streamed Insights
                                sessions – can I watch them later?</strong
                            ><br />
                            Yes, once the live airing is finished, the recording
                            will be available on demand for 3 months. Just click
                            on the “INSIGHTS” tab in the top menu bar, then on
                            “On Demand”.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >Can I download the Insights recordings to my
                                laptop?</strong
                            ><br />
                            No, the recordings can only be played within the
                            Midsummer platform. The recordings will be available
                            for 3 months.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >How long will the site be active for me to view
                                the on-demand sessions?</strong
                            ><br />
                            The site will remain active for 3 months after the
                            event ends.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >Why does my schedule include all the Insight
                                Sessions – I do not want to attend all of
                                them.</strong
                            ><br />
                            The Insights sessions are included in everyone’s
                            schedule, however, you can attend whichever ones you
                            are interested in. If you miss the live airing, you
                            can click on the ‘On Demand” tab under “INSIGHTS” to
                            watch the session later.
                        </p>
                    </li>
                </ul>
                <p>
                    <a href="#top"><strong>Back to Top</strong></a>
                </p>
            </div>
            <div>
                <h3 id="sponsors">Sponsors</h3>
                <ul>
                    <li>
                        <strong>Where can I find sponsor information?</strong
                        ><br />
                        Click on the “SOLUTIONS CENTER” tab in the top menu bar
                        to view sponsor information and connect with sponsors.
                    </li>
                    <li>
                        <strong
                            >Where can I find thought leadership presentations
                            from sponsors?</strong
                        ><br />
                        Thought leadership presentations from sponsors can be
                        found both:
                        <ul>
                            <li>
                                on the “INSIGHTS” page under “On Demand” and
                            </li>
                            <li>
                                by clicking on the sponsor’s logo on the
                                “SOLUTIONS CENTER” page.
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>
                    <a href="#top"><strong>Back to Top</strong></a>
                </p>
            </div>
            <div>
                <h3 id="communicating">
                    Communicating with Other Participants
                </h3>
                <ul>
                    <li>
                        <strong>Where can I see who is attending?</strong><br />
                        Click on the “ATTENDEES” tab on the top menu bar. You
                        will see a random selection of participants and can
                        search for other participants by first name, last name,
                        or company. A full, sortable list can also be found on
                        the Midsummer website:
                        <a href="https://www.fmi.org/midsummer/attendees"
                            >https://www.fmi.org/midsummer/attendees</a
                        >
                    </li>
                    <li>
                        <strong>Are the private meetings being recorded?</strong
                        ><br />
                        No, neither the meeting itself, nor the chat messages
                        are recorded.
                    </li>
                    <li>
                        <strong
                            >Can I get a transcript of the chat in the private
                            meeting rooms?</strong
                        ><br />
                        No, the chat messages are not recorded.
                    </li>
                    <li>
                        <strong
                            >Can I contact or message other attendees?</strong
                        ><br />
                        Yes, you can search for an attendee by first name, last
                        name, or company under the “ATTENDEES” tab on the top
                        menu bar. Then click on the “Send a Message” button.
                    </li>
                </ul>
                <p>
                    <a href="#top"><strong>Back to Top</strong></a>
                </p>
            </div>
            <div>
                <h3 id="troubleshooting">Troubleshooting</h3>
                <ul>
                    <li>
                        <p>
                            <strong
                                >How do I get help if I am having technical
                                issues?</strong
                            ><br />
                            There are several ways to get help if you are having
                            issues with the site.
                        </p>
                        <ol>
                            <li>
                                Click on the “HELP” tab on the top menu bar
                                (this page).
                            </li>
                            <li>
                                Click on the help icon on the bottom right
                                corner or your screen to chat live with a
                                support person.
                            </li>
                            <li>
                                Call
                                <a href="tel:4696763392">(469) 676-3392</a>.
                            </li>
                            <li>
                                Email us:
                                <a href="mailto:Midsummer@fmi.org"
                                    >Midsummer@fmi.org</a
                                >.
                            </li>
                            <li>
                                Live technical support is available every day of
                                the conference from 10:00am – 6:00pm ET.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>What should I do if?</strong>
                        <ul>
                            <li>
                                <strong
                                    >My scheduled meeting is not showing up on
                                    my schedule.</strong
                                ><br />
                                Contact Marc Thomas at
                                <a href="mailto:mthomas@fmi.org"
                                    >mthomas@fmi.org</a
                                >
                                or email the Midsummer help desk at
                                <a href="mailto:midsummer@fmi.org"
                                    >midsummer@fmi.org</a
                                >
                            </li>
                            <li>
                                <strong
                                    >I cannot access a private meeting.</strong
                                ><br />
                                Please click on the help icon on the bottom
                                right of your screen to chat live.
                            </li>
                            <li>
                                <strong
                                    >I cannot access a private meeting.</strong
                                ><br />
                                Please click on the help icon on the bottom
                                right of your screen to chat live.
                            </li>
                            <li>
                                <strong>My audio/video is buffering.</strong
                                ><br />
                                <ol>
                                    <li>
                                        Check the speed on your internet
                                        connection
                                    </li>
                                    <li>
                                        Try turning off your camera. A camera
                                        can consume a lot of bandwidth
                                    </li>
                                    <li>
                                        Close all other applications running on
                                        your device.
                                    </li>
                                    <li>
                                        Make sure that your device is as close
                                        to the router as possible. If possible,
                                        use a hard-wired connection
                                    </li>
                                    <li>
                                        Click on the help icon on the bottom
                                        right of your screen to chat live.
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>
                    <a href="#top"><strong>Back to Top</strong></a>
                </p>
            </div>
            <h2 id="best-practices">
                Best Practices for Meeting Participation
            </h2>
            <p>
                To ensure that your participation in Midsummer is as successful
                as possible, we recommend the following best practices:
            </p>
            <ul>
                <li>
                    <strong>Equipment</strong>
                    <ul>
                        <li>
                            <em>General requirements</em><br />
                            You will need access to either a PC, Mac, tablet, or
                            smartphone. A PC or Mac is recommended. To
                            participate in the meetings, it is recommended that
                            you have an internal or external camera so that you
                            can be seen on video.
                        </li>
                        <li>
                            <em>Microphones</em><br />
                            Clear audio can make a big difference. Laptop/phone
                            audio can work, but audio through an external
                            microphone (connected by wire or Bluetooth) can be a
                            huge plus. We recommend:
                            <ul>
                                <li>
                                    <a
                                        href="https://www.amazon.com/Logitech-Headset-H390-Noise-Cancelling/dp/B000UXZQ42/ref=psdc_3015405011_t3_B00L2JUJ68"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >Logitech USB Headset H390 with Noise
                                        Cancelling Mic</a
                                    >
                                </li>
                                <li>
                                    <a
                                        href="https://www.amazon.com/Education-1EDU-AE36WH-ITE-Headphone-Microphone-Single/dp/B00L2JUJ68/ref=sr_1_2?dchild=1&keywords=Avid+Education+1EDU-AE36WH-+ITE+Headphone+with+Boom+Microphone%2C&qid=1590542817&sr=8-2"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >Avid Education 1EDU-AE36WH-ITE
                                        Headphone with Boom Microphone, Single
                                        Plug, White</a
                                    >
                                </li>
                            </ul>
                        </li>
                        <li>
                            <em>Browser</em><br />
                            Midsummer will support the latest versions of
                            Chrome, Safari, Firefox, and Edge. IE11 is not fully
                            supported and you should use a different browser.
                        </li>
                        <li>
                            <em>Internet Connection Tips</em><br />
                            In general, wired connections are better than
                            wireless connections, and wireless connections are
                            better than cellular based connections.
                        </li>
                        <li>
                            While Midsummer supports tablets and smartphones, we
                            recommend using a PC or Mac as some functions will
                            not work on a tablet or phone.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Your Surroundings</strong>
                    <ul>
                        <li>
                            Find a quiet area for participation. Avoid areas
                            with loud background noise (traffic, wind, loud
                            vents, or appliances).
                        </li>
                        <li>
                            Be one with the light and find the balance! Try to
                            avoid a dark room. Natural room sunlight is good –
                            just do not stand with the light shining right
                            behind you.
                        </li>
                        <li>
                            Consider the acoustics of the room. A small room
                            with dead acoustics will not flatter a voice.
                        </li>
                        <li>
                            Position yourself relatively close to the camera
                        </li>
                        <li>
                            Frame the camera so that your eyes are in the top
                            third of the frame.
                        </li>
                        <li>
                            Use a plain, uncluttered background.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Meeting Participation</strong>
                    <ul>
                        <li>
                            We encourage participants to turn on their camera in
                            the private meeting rooms so that other participants
                            can see who is talking. But please note that only 16
                            people can be on video at once in the private
                            meeting rooms.
                        </li>
                        <li>
                            Please mute yourself when not talking to avoid
                            background interruptions (barking dogs, phones
                            ringing, family members talking etc.)
                        </li>
                        <li>
                            Please review the Code of Conduct for online
                            meetings
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Have a Meeting Plan</strong>
                    <ul>
                        <li>
                            Since only 16 people at a time can be on video, make
                            a plan with your team to decide who will be on
                            video, who will speak when, who will share the
                            presentation, etc.
                        </li>
                        <li>
                            Consider coordinating your meeting agenda with your
                            trading partner. You can contact them ahead of time
                            via email or phone (if you have their contact
                            information) or you can use the messaging feature in
                            Midsummer site.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Prepare for the Unexpected</strong>
                    <ul>
                        <li>
                            This is technology, some things *may* go wrong.
                        </li>
                        <li>
                            Have a back-up plan for if someone gets sick, if
                            someone’s internet connection drops, if a laptop
                            crashes, if someone’s audio goes haywire. Can
                            someone quickly take over the presentation?
                        </li>
                    </ul>
                </li>
            </ul>
            <p>
                <a href="#top"><strong>Back to Top</strong></a>
            </p>
            <h3 class="my-8">
                Have a terrific Midsummer meeting! Thank you for participating!
            </h3>
        </div>
        <div class="lg:w-1/4 lg:pl-8">
            <ul>
                <li>
                    <a href="#faq">Frequently Asked Questions</a>
                    <ul>
                        <li><a href="#login">Logging In</a></li>
                        <li>
                            <a href="#scheduled-meetings">Scheduled Meetings</a>
                        </li>
                        <li>
                            <a href="#insights">Insights and Learnings</a>
                        </li>
                        <li><a href="#sponsors">Sponsors</a></li>
                        <li>
                            <a href="#communicating"
                                >Communicating with Other Participants</a
                            >
                        </li>
                        <li>
                            <a href="#troubleshooting">Troubleshooting</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#best-practices"
                        >Best Practices for Meeting Participation</a
                    >
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "helpMidsummer"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
